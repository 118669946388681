import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Fade } from 'react-reveal';

// components
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import ContactForm from '../components/form/form';



function Kontakt() {
    return (
        <Layout>
            <Helmet>
                <title>Excendo Redovisning AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="Excendo Redovisning AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.excendo.se/" />
                <link rel="cannonical" href="http://www.excendo.se/" />
            </Helmet>

            <Navbar whitemode={true} />
            <div className="w-screen bg-gray-section-background">


                <section className="-mt-20">
                    {/*-------------- hero section - START ---------------*/}
                    <div className="w-screen bg-kontakta-oss-hero bg-center bg-no-repeat bg-cover">
                        <div className="custome_overlay flex justify-center items-center py-48">
                            <h1 className="opacity-0 text-4xl font-semibold text-white  text-center">Kontakt</h1>
                        </div>
                    </div>
                    {/*-------------- hero section - END ---------------*/}
                </section>


                <Fade bottom>
                    <ContactForm />
                </Fade>


                <div className="container mx-auto px-4 xl:px-24 my-24">
                    <hr className="border-1 border-lightgray-excendo"></hr>
                </div>


                <section>
                    {/*-------------- Map section - START ---------------*/}
                    <div className="container mx-auto px-4 xl:px-24 pb-24">
                        <div className="w-full lg:w-7/12 2xl:w-5/12 mx-auto flex flex-col items-center justify-center">
                            <p className="text-center">
                                <span className="bg-white text-blue-excendo font-semibold py-1 px-4 rounded-full shadow-md">Excendo</span>
                            </p>
                            <h2 className="mt-6 text-3xl lg:text-4xl font-semibold text-black-excendo text-center filter drop-shadow-md">Besöksadress</h2>
                            <p className="mt-6 text-center text-gray-excendo">Vi finns närmare än du tror!</p>
                        </div>

                        <div className="mt-8 w-full h-64 lg:h-96">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2042.7102190248927!2d17.661376116067263!3d59.204142481615264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f0d450bd483ad%3A0xc21ba55b0528a83!2sExcendo%20Accounting%20AB!5e0!3m2!1sen!2sse!4v1640700005497!5m2!1sen!2sse"
                            className="w-full h-full rounded-lg"
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </div>
                    </div>

                    
                    {/*-------------- Map section - END ---------------*/}
                </section>

            </div>


            <Footer/>
        </Layout>
    );
}

export default Kontakt;
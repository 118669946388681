import React from 'react';
import form_sent_img from "../../images/form-sent.jpg";


class ContactForm extends React.Component{
    constructor(){
        super()
        this.state = {
          name: '',
          phone: '',
          email: '',
          messege: '',
          name_err: false,
          phone_err: false,
          email_err: false,
          messege_err: false,
          contact_sent: false
        }

        //this.handle_submit = this.handle_submit.bind(this);
    }


    //CHECK IF FORM IS FILLED AND SEND THE DATA TO DATABASE
    handle_submit(){
        //event.preventDefault();

        if(this.state.name.length <= 2){this.setState({name_err: true});}
        else{this.setState({name_err: false});}

        if(this.state.phone.length <= 2){this.setState({phone_err: true});}
        else{this.setState({phone_err: false});}

        if(this.state.email.length <= 2){this.setState({email_err: true});}
        else{this.setState({email_err: false});}

        if(this.state.messege.length <= 2){this.setState({messege_err: true});}
        else{this.setState({messege_err: false});}

        if(this.state.name.length >= 2 && this.state.phone.length >= 2 && this.state.email.length >= 2 && this.state.messege.length >= 2){  
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    name: this.state.name,
                    phone: this.state.phone,
                    email: this.state.email,
                    message: this.state.messege,
                })
            };
            
            fetch('https://excendo.hamraz.se/admin/api/message.php', requestOptions)
            .then(res => res.json())
            .then( json => {
                if(json.db === true){
                    this.setState({
                        contact_sent: true,
                    });
                }else{
                    this.setState({
                        contact_sent: false,
                    });
                }
            })

        }else {
            this.setState({
                contact_sent: false,
            });
        }
    }

    render(){
        return(
            <>
                {this.state.contact_sent ? 
                    <div className="-mt-20 relative max-w-4xl mx-auto flex justify-center">
                        <h1 className="text-2xl absolute top-12 font-bold text-center text-white">Tack! Vi återkommer inom kort.</h1>
                        <img className="w-full h-full rounded-lg " src={form_sent_img} alt="success" />
                    </div>
                :
                <section className="-mt-20 w-full max-w-4xl px-6 py-12 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800">
                    <h1 className="text-4xl font-semibold text-black-excendo text-center">Kontakt</h1>
                    <p className="mt-3 text-center text-gray-600 dark:text-gray-400">Tveka inte att kontakta oss via telefon, e-post, formulär eller varför inte komma på besök!</p>
                    
                    <div className="grid grid-cols-1 gap-6 mt-12 sm:grid-cols-2 md:grid-cols-3">
                        <div className="flex flex-col items-center justify-center px-4 py-3 text-gray-excendo rounded-md bg-gray-50">
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                            </svg>

                            <span className="mt-2">Gränsbovägen 4 <br/> 152 42 Södertälje</span>
                        </div>

                        <div className="flex flex-col items-center justify-center px-4 py-3 text-gray-excendo rounded-md bg-gray-50">
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                            </svg>

                            <span className="mt-2">+46 8-55 035 055</span>
                        </div>

                        <div className="flex flex-col items-center justify-center px-4 py-3 text-gray-excendo rounded-md bg-gray-50">
                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>

                            <span className="mt-2">info@excendo.se</span>
                        </div>
                    </div>
                    <div className="mt-12 ">
                        <div className="items-center md:flex">
                            <div className="w-full md:mr-2">
                                <div className="flex items-end justify-between">
                                    <span className="block mb-1 text-sm font-medium text-gray-600">Namn</span>
                                    <span className="text-xs text-red-600">{this.state.name_err ? "Fylla i ditt förnamn och efternamn" : ""}</span>
                                </div>
                                

                                <input 
                                    className="block w-full px-4 py-2 text-black-excendo bg-white border border-gray-300 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" 
                                    placeholder="Jane Doe"
                                    value={this.state.name}
                                    onChange={(e)=>{this.setState({name: e.target.value});}}
                                    type="text" 
                                    name="name" 
                                />
                            </div>

                            <div className="w-full md:ml-2">
                                <div className="flex items-end justify-between">
                                    <span className="block mb-1 text-sm font-medium text-gray-600">Telefonnummer</span>
                                    <span className="text-xs text-red-600">{this.state.phone_err ? "Telefonnummer får inte vara tom" : ""}</span>
                                </div>

                                <input 
                                    className="block w-full px-4 py-2 text-black-excendo bg-white border border-gray-300 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" 
                                    placeholder="070-000 00 00"
                                    value={this.state.phone}
                                    onChange={(e)=>{this.setState({phone: e.target.value});}}
                                    type="text" 
                                    name="phone" 
                                />
                            </div>
                        </div>

                        <div className="w-full mt-4 ">
                            <div className="flex items-end justify-between">
                                <span className="block mb-1 text-sm font-medium text-gray-600">E-postadress</span>
                                <span className="text-xs text-red-600">{this.state.email_err ? "E-post får inte vara tom" : ""}</span>
                            </div>

                            <input 
                                className="block w-full px-4 py-2 text-black-excendo bg-white border border-gray-300 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                placeholder="namn@exempel.se" 
                                value={this.state.email}
                                onChange={(e)=>{this.setState({email: e.target.value});}}
                                type="email" 
                                name="email"     
                            />
                        </div>

                        <div className="w-full mt-4">
                            <div className="flex items-end justify-between">
                                <span className="block mb-1 text-sm font-medium text-gray-600">Meddelande</span>
                                <span className="text-xs text-red-600">{this.state.messege_err ? "Beskriv kort ditt ärende" : ""}</span>
                            </div>

                            <textarea 
                                className="block w-full h-40 px-4 py-2 text-black-excendo bg-white border border-gray-300 rounded-md focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                                placeholder="Hur kan vi hjälpa dig?" 
                                value={this.state.messege}
                                onChange={(e)=>{this.setState({messege: e.target.value});}}
                                name="message" 
                            ></textarea>
                        </div>

                        <div className="flex justify-center mt-12">
                            <button 
                                className="pl-2 pb-2 hover:pl-1 hover:pr-1 hover:pb-1 hover:pt-1  focus:pl-0 focus:pr-2 focus:pb-0 focus:pt-2 transition transform ease-in-out duration-200 border-2 border-orange-excendo rounded-md "
                                onClick={()=>{ this.handle_submit() }}
                            >
                                <span className="block bg-orange-excendo px-10 py-1 rounded-lg text-white -mr-4 -mt-4 border-4 border-orange-excendo shdaow-md">Skicka</span>
                            </button>
                        </div>
                    </div>
                </section> 
                } 
            </>
        );
    }
}
export default ContactForm;